import gql from "graphql-tag";

//  CREATE teacher
export const ADD_TEACHER = gql`
  mutation(
    $name: String!
    $nama_lengkap: String!
    $email: String!
    $password: String!
    $role_id: roleId!
  ) {
    createTeacher(
      input: {
        user: {
          create: {
            name: $name
            nama_lengkap: $nama_lengkap
            email: $email
            password: $password
            role_id: $role_id
          }
        }
      }
    ) {
      id
      user {
        id
        name
        nama_lengkap
        email
      }
    }
  }
`;
// READ peserta
export const GET_TEACHER = gql`
  query {
    allTeacher {
      id
      user {
        name
        nama_lengkap
        email
      }
    }
  }
`;
// UPDATE peserta
export const EDIT_TEACHER = gql`
  mutation($id: ID!, $nama: String!, $alamat: String!) {
    updatePeserta(id: $id, nama: $nama, alamat: $alamat) {
      id
      nama
      alamat
    }
  }
`;
// DELETE peserta
export const DELET_TEACHER = gql`
  mutation($id: ID!) {
    deleteTeacher(id: $id) {
      id
    }
  }
`;
