<template>
  <div class="container-admin">
    <nav class="d-flex justify-start pa-5">
      <addAdmin />
    </nav>
    <div class="temp-list d-flex justify-center">
      <v-card class="temp-table ma-5">
        <v-card-title>
          List Admin
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="allPembimbing"
            :search="search"
          >
            <div v-if="$apollo.queries.allPembimbing.loading">Loading ...</div>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-around">
                <v-btn fab dark small color="cyan">
                  <v-icon dark small @click="editPage(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red"
                  @click.prevent="deleteConfirm(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-card width="30%" class="profile-teacher ma-5">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <!-- <v-img height="250" src="../../assets/img/isyanaku.jpg"></v-img> -->
        <v-form ref="form" lazy-validation class="pa-3">
          <v-text-field
            v-model="profile.name"
            :counter="100"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            v-model="profile.id"
            :counter="15"
            label="Status"
            required
          ></v-text-field>
          <v-text-field
            v-model="profile.email"
            :counter="100"
            label="Alamat"
            required
          ></v-text-field>

          <v-card-actions>
            <v-btn color="success" class="mr-4" @click="updatePeserta">
              Simpan
            </v-btn>

            <v-btn color="error" class="mr-4" @click="close"> Batal </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import addAdmin from "../../components/Admin/Modal/addAdmin";
import Swal from "sweetalert2";
import {
  DELET_TEACHER,
  GET_TEACHER,
  EDIT_TEACHER
} from "../../plugins/graphQL";

export default {
  name: "listAdmin",

  // apollo query
  apollo: {
    allPembimbing: {
      query: GET_TEACHER
    }
  },
  components: {
    addAdmin
  },
  data() {
    return {
      name: "",
      search: "",
      profile: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "user.name"
        },
        { text: "id", value: "id" },
        { text: "email", value: "user.email" },
        { text: "Aksi", value: "actions" },
      ],
    };
  },
  methods: {
    editPage(item) {
      this.profile = item;
      document.querySelector(".profile-teacher").style.display = "block";
      document.querySelector(".temp-table").style.width = "70%";
    },
    updatePeserta() {
      this.$apollo.mutate({
        mutation: EDIT_TEACHER,
        variables: {
          id: this.profile.id,
          nama: this.profile.nama,
          nama_lengkap: this.profile.alamat
        },
        update: (store, { data: { updatePembimbing } }) => {
          if (updatePembimbing) {
            const data = store.readQuery({
              query: GET_TEACHER
            });
            data.allPembimbing.find(i => i.id === this.profile.id);
            store.writeQuery({
              query: GET_TEACHER,
              data
            });
          }
        }
      });
      this.close();
      Swal.fire({
        icon: "success",
        title: "Profile Admin telah terupdate",
        showConfirmButton: false,
        timer: 3000,
        position: "top",
        toast: true
      });
    },
    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus penyelenggara",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus data!",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo.mutate({
            mutation: DELET_TEACHER,
            variables: {
              id: item.id
            },
            update: (store, { data: { deletePembimbing } }) => {
              if (deletePembimbing.id) {
                const data = store.readQuery({
                  query: GET_TEACHER
                });
                data.allPembimbing = data.allPembimbing.filter(i => {
                  return i.id !== item.id;
                });
                store.writeQuery({
                  query: GET_TEACHER,
                  data
                });
              }
            }
          });
          Swal.fire({
            icon: "success",
            title: "Admin telah terhapus",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        }
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    close() {
      document.querySelector(".profile-teacher").style.display = "none";
      document.querySelector(".temp-table").style.width = "100%";
    }
  }
};
</script>

<style>
.profile-teacher {
  display: none;
}
.temp-table {
  width: 100%;
}
</style>
