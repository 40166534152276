<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" dark color="indigo">
          <v-icon dark> mdi-plus </v-icon>
          Tambah Admin
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Tambah Penyelenggara</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama"
                  required
                  v-model="nama"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nama Lengkap"
                  required
                  v-model="nama_lengkap"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Kata Sandi"
                  required
                  v-model="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="addPeserta"> Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
// import gql from "graphql-tag";
import { GET_TEACHER, ADD_TEACHER } from "../../../plugins/graphQL";

export default {
  data: () => ({
    id: null,
    nama: "",
    nama_lengkap: "",
    email: "",
    password: "",
    dialog: false,
    rules: [
      value =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!"
    ]
  }),
  methods: {
    addPeserta() {
      const name = this.nama;
      const nama_lengkap = this.nama_lengkap;
      const email = this.email;
      const password = this.password;
      const role_id = "2";
      this.$apollo.mutate({
        mutation: ADD_TEACHER,
        variables: {
          name: name,
          nama_lengkap: nama_lengkap,
          email: email,
          password: password,
          role_id: role_id
        },
        update: (chace, { data: { createTeacher } }) => {
          try {
            const data = chace.readQuery({
              query: GET_TEACHER
            });
            const createTeach = createTeacher;
            data.allPembimbing.splice(0, 0, createTeach);
            chace.writeQuery({
              query: GET_TEACHER,
              data
            });
          } catch (e) {
            console.log(e);
            console.log("ini error kenaoa");
          }
          this.nama = "";
          this.alamat = "";
          this.dialog = false;
          Swal.fire({
            icon: "success",
            title: "Anda telah menambahkan admin",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        }
      });
    }
  }
};
</script>

<style></style>
